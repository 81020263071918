import create from 'zustand'
import { persist } from 'zustand/middleware'

import { ICV } from 'validators/new/cv.validator'

import { OnBoardingStatus } from '../enums'

export interface CVNewStore {
  majorStep: number
  setMajorStep: (majorStep: number) => void
  passedSteps: number[]
  activeStep: number
  cvFile: File | null
  tempCV: Partial<ICV> | null

  isEditing: boolean
  privacyAccept: boolean
  isModalAvatar: boolean
  isModalBasic: boolean
  isModalSkills: boolean
  modalWork: number | null
  modalEducation: number | null
  isModalWork: boolean
  isModalEducation: boolean
  isModalLanguages: boolean
  isModalPreferences: boolean

  setIsEditing: (isEditing: boolean) => void
  setModalAvatar: (isModalAvatar: boolean) => void
  setModalBasic: (isModalBasic: boolean) => void
  setModalSkills: (isModalSkills: boolean) => void
  setModalWork: (modalWork: number | null) => void
  setModalEducation: (modalEducation: number | null) => void
  setModalLanguages: (isModalLanguages: boolean) => void
  setModalPreferences: (isModalPreferences: boolean) => void
  closeModals: () => void
  setCvFile: (cvFile: File | null) => void
  setPrivacyAccept: (privacyAccept: boolean) => void
  setStep: (step: number) => void
  incStep: (step: OnBoardingStatus) => void
  decStep: (step: OnBoardingStatus, isMajor?: boolean) => void
  setTempCV: (tempCV: Partial<ICV> | null) => void
  clearCV: () => void
}

const useCVNewStore = create<CVNewStore>()(
  persist(
    (set) => ({
      cvFile: null,
      passedSteps: [0],
      activeStep: 0,
      tempCV: null,
      majorStep: 0,
      isEditing: false,
      isModalAvatar: false,
      isModalBasic: false,
      isModalSkills: false,
      modalWork: null,
      modalEducation: null,
      isModalWork: false,
      isModalEducation: false,
      isModalLanguages: false,
      isModalPreferences: false,
      setIsEditing: (isEditing: boolean) => set(() => ({ isEditing })),
      setModalAvatar: (isModalAvatar) =>
        set((state) => ({ ...state, isModalAvatar })),
      setModalBasic: (isModalBasic) =>
        set((state) => ({ ...state, isModalBasic })),
      setModalSkills: (isModalSkills) =>
        set((state) => ({ ...state, isModalSkills })),
      setModalWork: (modalWork) =>
        set((state) => ({ ...state, modalWork, isModalWork: true })),
      setModalEducation: (modalEducation) =>
        set((state) => ({ ...state, modalEducation, isModalEducation: true })),
      setModalLanguages: (isModalLanguages) =>
        set((state) => ({ ...state, isModalLanguages })),
      setModalPreferences: (isModalPreferences) =>
        set((state) => ({ ...state, isModalPreferences })),
      closeModals: () =>
        set((state) => ({
          ...state,
          isModalAvatar: false,
          isModalBasic: false,
          isModalSkills: false,
          isModalEducation: false,
          isModalWork: false,
          isModalLanguages: false,
          isModalPreferences: false
        })),
      setMajorStep: (majorStep: number) => set(() => ({ majorStep })),
      setCvFile: (cvFile: File | null) =>
        set((state) => ({ ...state, cvFile })),
      privacyAccept: false,
      setPrivacyAccept: (privacyAccept: boolean) =>
        set((state) => ({ ...state, privacyAccept })),
      setStep: (step: number) =>
        set((state) => {
          const passedSteps = Array.from(
            new Set([
              ...state.passedSteps,
              ...Array.from(Array(step).keys()),
              step
            ])
          )
          // TODO check, possibly not working, when redirecting to setStep(stepLength - 1)
          return { ...state, activeStep: step, passedSteps }
        }),
      incStep: (step: OnBoardingStatus) =>
        set((state) => {
          return {
            ...state,
            activeStep: step
          }
        }),
      decStep: (step: OnBoardingStatus, isMajor) =>
        set((state) => ({
          ...state,
          activeStep: step,
          majorStep: Math.max(
            isMajor ? state.majorStep - 1 : state.majorStep,
            0
          )
        })),
      setTempCV: (cvValue) =>
        set((state) => ({
          ...state,
          tempCV: cvValue ? { ...state.tempCV, ...cvValue } : cvValue
        })),
      clearCV: () =>
        set((state) => ({
          ...state,
          cvFile: null,
          passedSteps: [0],
          activeStep: 0,
          tempCV: null,
          majorStep: 0,
          isEditing: false,
          isModalAvatar: false,
          isModalBasic: false,
          modalWork: null,
          modalEducation: null,
          isModalLanguages: false,
          isModalPreferences: false
        }))
    }),
    {
      name: 'hrizer-cv-new',
      getStorage: () => {
        return localStorage
      }
    }
  )
)

export default useCVNewStore
